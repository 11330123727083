<div>
    <div class="content-container">
        <mat-icon id="close-icon" (click)="closedialog()">close</mat-icon>
        <span class="content-span full-width">{{data.message}}</span>
    </div>
    <div class="grid">        
            <!-- <div class="column"> -->
                <button class="mat-flat-button" [mat-dialog-close]="false" id="no-button">No</button>
            <!-- </div>
            <div class="column"> -->
                <button class="mat-flat-button" [mat-dialog-close]="true" id="yes-button">Yes</button>
            <!-- </div> -->
        
    </div>
</div>